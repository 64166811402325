import React, { useState } from "react";
import { Dropdown, Layout, Menu } from "antd";
import {
  UserOutlined,
  DesktopOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import "./style.css";
import Footer from "../footer/footer";
import logo from "../images/logo_2.svg";

import { useDispatch, useSelector } from "react-redux";
import { setLoginState } from "../../redux/user";

const { Content, Sider } = Layout;

export default function LayoutsMain() {
  const dispatch = useDispatch();
  const [panelId, setpanelId] = useState(1);
  const user = useSelector((state) => state.user.user);
  const items = [
    {
      label: (
        <div
          onClick={() => {
            dispatch(setLoginState(false));
          }}
          style={{ cursor: "pointer" }}
        >
          <LogoutOutlined /> log Out
        </div>
      ),
      key: "1",
    },
  ];
  return (
    <Layout>
      <Sider
        style={{
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
          backgroundColor: "black",
        }}
      >
        <div className="logo">
          <img src={logo} alt="" className="logo-size" />
        </div>
        <Menu mode="inline" defaultSelectedKeys={["1"]}>
          <Menu.Item
            key={1}
            icon={<DesktopOutlined />}
            onClick={() => setpanelId(1)}
            style={{color:"white"}}
          >
            Footer
          </Menu.Item>
     
          <Menu.Item
            key={3}
            icon={<LogoutOutlined />}
            onClick={() => {
              dispatch(setLoginState(false));
            }}
            style={{color:"white"}}
          >
            LogOut
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout
        className="site-layout"
        style={{
          marginLeft: 200,
        }}
      >
        <div className="backgroundwhole">
          <div className="dashtextdiv">
            <h1 className="dashtext">Social_Media</h1>
            <div className="lgodiv">
              <div style={{ display: "flex", color: "white" }}>
                <UserOutlined style={{ paddingRight: "0.25rem" }} />
                <Dropdown
                  menu={{
                    items,
                  }}
                >
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={(e) => e.preventDefault()}
                  >
                    {user ? `Welcome ${user.email}` : " log out"}
                  </div>
                </Dropdown>
              </div>{" "}
            </div>
          </div>
        </div>
        <Content
          style={{
            margin: "24px 16px 0",
            overflow: "initial",
          }}
        >
          <div
            className="site-layout-background"
            style={{
              padding: 24,
              textAlign: "center",
              height: "auto",
            }}
          >
            {/* {panelId === 1 && <Dashboard />} */}
            {panelId === 1 && <Footer />}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}
